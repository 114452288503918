import * as moment from 'moment-timezone';
import FeatherIcon from "feather-icons-react";
import React from "react";
import {MODULE, WHITE_LABEL} from "./Enum";
import * as _ from "underscore";
import moisture from '../images/icons/moisture .svg';
import pH from '../images/icons/ph.svg';
import limitexceed from '../shared/images/icons/basic-detail-icon.svg';
import {isEmpty} from "../shared/utils/utils";
import {getRefCount, getReferences, getRole} from "../shared/utils/SharedAuthentication";
import {containerClient} from "./Authentication";
import S3 from 'react-aws-s3';
import wifiOff from "../images/icons/wifi-off.svg";
import wifi1 from "../images/icons/wifi-1.svg";
import wifi2 from "../images/icons/wifi-2.svg";
import wifi3 from "../images/icons/wifi-3.svg";
import wifi from "../images/icons/wifi.svg";
import battery5 from "../images/icons/battery-5.svg";
import battery4 from "../images/icons/battery-4.svg";
import battery3 from "../images/icons/battery-3.svg";
import battery2 from "../images/icons/battery-2.svg";
import battery1 from "../images/icons/battery-1.svg";
import battery0 from "../images/icons/battery-0.svg";

const options = {
  dirName: "farm-images",
  bucketName: 'senzagro-app',
  region: 'us-east-1',
  accessKeyId: 'AKIA5XGNU22II44QCSGH',
  secretAccessKey: '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',
  s3Url: 'https://senzagro-app.s3.amazonaws.com',
}

const ReactS3Client = new S3(options);

export function uploadImage(imageName, imageFile) {
  if (process.env.REACT_APP_STORAGE === "AZURE") {
    const blobClient1 = containerClient.getBlockBlobClient(imageName);
    const options1 = {blobHTTPHeaders: {blobContentType: 'image'}};

    return new Promise((async (resolve, reject) => {
      await blobClient1.uploadBrowserData(imageFile, options1)
        .then((response) => {
          resolve(blobClient1.url);
        })
        .catch(e => {
          reject(e);
        })
    }));
  } else {
    return new Promise(((resolve, reject) => {
      ReactS3Client.uploadFile(imageFile)
        .then((response) => {
          resolve(response.location);
        })
        .catch(e => {
          reject(e);
        })
    }));
  }
}

export function removeEmpty(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .filter(([_, v]) => v !== "")
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
}

export function batteryReading(value, low, high) {
  if (value > 150 || value < 75) {
    value = (value - low) / (high - low);
    value = value < 0 ? 0.0 : value > 1 ? 1.0 : value;
  } else {
    value = -1.0;
  }
  return Math.round(value * 100);

}

export function getBatteryIcon(batteryLevel) {
  let icon;
  if (batteryLevel > 80) {
    icon = battery5;
  } else if (batteryLevel > 50) {
    icon = battery4;
  } else if (batteryLevel > 25) {
    icon = battery3;
  } else if (batteryLevel > 10) {
    icon = battery2;
  }else if (batteryLevel >= 1) {
    icon = battery1;
  } else {
    icon = battery0;
  }
  return (<img src={icon} alt="Battery Icon"/>);
}

export function getWifiIcon(signalQuality) {
  let iconPath;
  if (signalQuality <= 1) {
    iconPath = wifiOff;
  } else if (signalQuality <= 9) {
    iconPath = wifi1;
  } else if (signalQuality <= 14) {
    iconPath = wifi2;
  } else if (signalQuality <= 19) {
    iconPath = wifi3;
  } else {
    iconPath = wifi;
  }
  return <img src={iconPath} alt="WiFi Icon"/>;
}

export function isAllFalse(obj) {
  for (let prop in obj) {
    if (obj[prop]) {
      return false;
    }
  }

  return true;
}

export function isDeviceOnline(time, interval) {
  if (!time || !interval) {
    return false;
  }

  interval = interval * 3;

  const today = new Date();
  const ist = moment.tz(time, 'Asia/Colombo');
  const ut = ist.clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const diffMs = (today - ut);
  const diffMinutes = Math.round(diffMs / 60000);
  return diffMinutes <= interval;
}

export function toCapsFirst(name) {
  if (name) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase();
  } else {
    return "";
  }
}

export function updateStateArray(values) {
  return values.map((item, index) => {
    return {...item, uiUpdate: index + new Date()}
  })
}

export function convertRoleIdToUserRole(roleId) {
  let userRole = "";
  switch (roleId) {
    case 1 :
      userRole = "Super";
      break;
    case 5 :
      userRole = "System Maintenance User";
      break;
    case 10 :
      userRole = "Account User";
      break;
    case 100 :
      userRole = "Corporate User";
      break;
    case 200 :
      userRole = "Corporate Admin User";
      break;
    case 1000 :
      userRole = "Farm User";
      break;
    case 2000 :
      userRole = "Agro Inspector";
      break;
    case 10000 :
      userRole = "Plot User";
      break;
  }

  return userRole;
}

export function timeDurationToReadableForm(startTime, endTime) {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const diffMs = (endDate - startDate); // milliseconds between now & Christmas
  const diffDays = Math.floor(diffMs / 86400000); // days
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  if (diffDays === 0) {
    if (diffHrs === 0) {
      return (diffMins + " minutes");
    }
    return (diffHrs + " hours, " + diffMins + " minutes");
  }
  return (diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
}

export function minsToDaysHoursMins(num) {
  const d = Math.floor(num / 1440);
  const h = Math.floor((num - (d * 1440)) / 60);
  const m = Math.round(num % 60);
  if (d > 0) {
    return (d + " days, " + h + " hours, " + m + " minutes");
  } else {
    return (h + " hours, " + m + " minutes");
  }
}


export function isMobileView() {
  return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|L|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4));

}


export function convert24to12(time) {
  if (!is24HRSTime(time)) {
    return time;
  }
  if (time) {
    time = time.split(':');
    let hour;
    if (12 <= time[0]) {
      if (parseInt(time[0]) === 12) {
        hour = 12;
      } else {
        hour = time[0] - 12;
      }
      if (hour.toString().length === 1) {
        hour = "0" + hour;
      }
      return hour + ':' + time[1] + ' pm';
    } else {
      if (parseInt(time[0]) === 0) {
        hour = 12;
      } else {
        hour = time[0];
      }
      if (hour.toString().length === 1) {
        hour = "0" + hour;
      }
      return hour + ':' + time[1] + ' am';
    }
  }
}

export function convert12Hto24H(time) {
  if (is24HRSTime(time)) {
    return time;
  }
  if (time) {
    const PM = time.match('pm');
    time = time.split(':');
    let min = time[1].split(' ')[0];
    let hour;
    if (PM) {
      const hourT = parseInt(time[0], 10);
      if (hourT < 12) {
        hour = 12 + hourT;
      } else {
        hour = 12;
      }
    } else {
      if (parseInt(time[0], 10) === 12) {
        hour = 0;
      } else {
        hour = time[0];
      }
    }
    if (min.match("pm") || min.match("am")) {
      min = min.slice(0, min.length - 2);
      min.trim();
    }

    if (hour.toString().length === 1) {
      hour = "0" + hour;
    }
    return hour + ':' + min;
  }
}

export function isValidDate(dateValue) {
  if (!dateValue) {
    return false
  }
  dateValue = dateValue.toISOString().slice(0, 10);
  return dateValue && dateValue.match(/^\d{4}-\d{2}-\d{2}$/) != null;
}

export function is24HRSTime(time) {
  if (!time) {
    return
  }
  return !(time.match("am") || time.match("pm"))
}

export function isEmail(str) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(str);
}

export function isSLMobile(str) {
  const re = /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;
  return re.test(str);
}

export function isSL_NIC(str) {
  const re = /^(?:19|20)?\d{2}[0-9]{10}|[0-9]{9}[x|X|v|V]$/;
  return re.test(str);
}


export function convertLowerCase(value) {
  if (!value) return "";
  return value.toString().toLowerCase();
}

export function formatDisplayEnumValue(value) {
  if (!value) return "";
  return toCapsFirst(value.toString().replace(/_/g, " "));
}

// Converts camelCase to readable format. Eg: camelToReadable("camelCase") => "Camel Case"
export function camelToReadable(str) {
  return str
    ? str.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())
    : "";
}

export function getIcon(code, isActuator) {
  if (isActuator) {
    return getIconActuator(code);
  }
  switch (code) {
    case 'T':
      return (<div className="sensor-icon sensor-icon-size-40 text-red padding-top-0"><FeatherIcon className={""}
                                                                                                   icon="thermometer"/>
      </div>);
    case 'H':
      return (<div className="sensor-icon sensor-icon-size-40 text-blue"><FeatherIcon className={"sensor-icon-moisture"}
                                                                                      icon="droplet"/></div>);
    case 'M':
      return (<div className="sensor-icon sensor-icon-size-40 text-blue"><img className={"sensor-icon-moisture m-t_-2"}
                                                                              height={'30px'} width={'24px'}
                                                                              src={moisture} alt="bin"/></div>);
    case 'IRO':
      return (
        <div className="sensor-icon sensor-icon-size-40 text-yellow"><span className='icon-sa-moisture icon-moisture'/>
        </div>);
    case 'CN':
      return (<div className="sensor-icon sensor-icon-size-40 text-yellow"><span
        className='icon-sa-moisture icon-soil-conductivity'/></div>);
    case 'ST':
      return (
        <div className="sensor-icon sensor-icon-size-40 text-yellow"><span className='icon-sa-moisture icon-soil-temp'/>
        </div>);
    case 'EV':
      return (<div className="sensor-icon sensor-icon-size-40 text-yellow"><span
        className='icon-sa-moisture icon-evaporation'/></div>);
    case 'WD':
      return (<div className="sensor-icon sensor-icon-size-40 text-yellow"><span
        className='icon-sa-moisture icon-wind-direction'/></div>);
    case 'WS':
      return (<div className="sensor-icon sensor-icon-size-40 text-yellow"><span
        className='icon-sa-moisture icon-wind-speed'/></div>);
    case 'RF':
      return (
        <div className="sensor-icon sensor-icon-size-40 text-yellow"><span className='icon-sa-moisture icon-rain-fall'/>
        </div>);
    case 'SI':
      return (<div className="sensor-icon sensor-icon-size-40 text-yellow"><span
        className='icon-sa-moisture icon-sun-intensity'/></div>);
    case 'LIA1':
      return (<div className="sensor-icon sensor-icon-size-40 text-yellow pt-1"><span
        className='icon-sa-moisture icon-sun-intensity'/></div>);
    case 'CS':
      return (<div className="sensor-icon sensor-icon-size-40 text-red padding-top-0"><FeatherIcon className={""}
                                                                                                   icon="activity"/>
      </div>);
    default:
      return (<div className="sensor-icon sensor-icon-size-40"><img className={"sensor-icon-moisture m-t_-2"}
                                                                    height={'30px'} width={'24px'}
                                                                    src={pH} alt="bin"/>
      </div>);

  }
}

export function getIconActuator(code) {
  switch (code) {
    case 'M': //motor
      return (<div className="sensor-icon sensor-icon-size-40 text-blue padding-top-0"><FeatherIcon className={""}
                                                                                                    icon="crosshair"/>
      </div>);
    case 'S': //Solenoid
      return (<div className="sensor-icon sensor-icon-size-40 text-black pt-0"><FeatherIcon className={""}
                                                                                            icon="git-merge"/></div>);
    default:
      return (<div className="sensor-icon sensor-icon-size-40 text-red padding-top-0"><FeatherIcon className={""}
                                                                                                   icon="framer"/>
      </div>);

  }
}


export function editAddIcon(btnType) {
  switch (btnType) {
    case "EDIT":
    case "Edit":
      return (<span className={'btn btn-sa-secondary edit-btn-color btn-moblie-veiw w-140p'}><FeatherIcon
        className={"text-gray icon-setting"} icon="edit-2"/><span className={'hide-425'}>{btnType}</span></span>)
    default :
      return (<span className={'btn btn-sa-secondary add-btn-moblie-veiw btn-moblie-veiw w-140p'}><FeatherIcon
        className={"text-green icon-setting"} icon="plus"/><span className={'hide-425'}>{btnType}</span></span>)
  }
}

export function getColouredStatus(type) {
  let typeF = formatDisplayEnumValue(type);
  switch (type) {
    case "NA":
    case "N/A":
      return (<span className="text-gray">{typeF}</span>)
    case "OFF":
      return (<span className="text-red">{typeF}</span>)
    case "ON":
      return (<span className="text-green">{typeF}</span>)
    case "MOTOR":
    case "MIST":
    case "MANUAL":
    case "DAILY":
      return (<span className="text-yellow">{typeF}</span>)
    default :
      return (<span className="text-yellow">{typeF}</span>)
  }
}

export function convertSensorCodeToSensorName(letter) {
  switch (letter) {
    case "T":
      return "Temperature"
    case "H":
      return "Humidity"
    case "M":
      return "Moisture"
    case "ST":
      return "Soil Temperature"
    case "CN":
      return "Soil Conductivity"
    default :
      return letter
  }
}

export function formatDisplayValue(value) {
  if (value === "ERROR") {
    return "-";
  }
  return value;
}

export function getSensorName(value, number, kit) {
  if (kit.metaData && kit.metaData.propertyNameMap) {

    let mapping = JSON.parse(kit.metaData.propertyNameMap);

    if (mapping[number]) {
      return mapping[number];
    }
  }
  return value === "Temp" ? "Temperature" : value;
}

export function isModule(url, module) {
  switch (module) {
    case MODULE.SMART_FARM:
      return !url.match("/daily-update") && !url.match("/cash-flow") && !url.match("/settings");
    case MODULE.CASH_FLOW:
      return url.match("/cash-flow");
    case MODULE.DASHBOARD:
      return url.match("/dashboard");
    case MODULE.DAILY_UPDATE:
      return url.match("/daily-update");
    case MODULE.SETTINGS:
      return url.match("/settings");
    case MODULE.REPORT:
      return url.match("/reports");
    case MODULE.MAP_DATA:
    case MODULE.BIRD_VIEW:
      return url.match("/map-data");
    case MODULE.XINDICATE:
      return url.match("/xindicate");
    case MODULE.CORPORATE:
      return url.match("/corporate");
    case MODULE.BUYER_PROFILE:
      return url.match("/buyer");
    case MODULE.CCR_PROFILE:
      return url.match("/ccr");
    case MODULE.ACCOUNT_SETTING:
      return url.match("/account");
  }
}

export function getModule(url) {
  if (!url.match("/daily-update") && !url.match("/cash-flow") && !url.match("/corporate") && !url.match("/buyer") && !url.match("/account") && !url.match("/settings") && !url.match("/dashboard") && !url.match("/map-data") && !url.match("/alltasks"))
    return MODULE.SMART_FARM;
  else if (url.match("/cash-flow"))
    return MODULE.CASH_FLOW
  else if (url.match("/daily-update") || url.match("/alltasks"))
    return MODULE.DAILY_UPDATE
  else if (url.match("/map-data"))
    return MODULE.MAP_DATA
  else if (url.match("/settings"))
    return MODULE.SETTINGS
  else if (url.match("/dashboard"))
    return MODULE.DASHBOARD
  else if (url.match("/corporate"))
    return MODULE.CORPORATE
  else if (url.match("/buyer"))
    return MODULE.BUYER_PROFILE
  else if (url.match("/reports"))
    return MODULE.REPORT;
  else if (url.match("/account"))
    return MODULE.ACCOUNT_SETTING;
}


export function isMobile() {
  return window.screen.width > 1000
}


// export function isMobileView() {
//   return window.screen.width<769
// }

export function clusterSensorsByDeviceId(kit, selectedPlot) {
  if (isEmpty(kit)) {
    // dispatch(getClusters([]));

    return [];
  }
  const clusteredArray = [[]];
  for (const [key, value] of Object.entries(selectedPlot.propertyDetails)) {

    let clusterIndex = -1;
    for (let i = 0; i < clusteredArray.length; i++) {
      let cluster = clusteredArray[i];

      if (cluster.length > 0 && cluster[0].deviceId === value.cluster) {
        clusterIndex = i;
        break;
      }
    }

    if (kit.properties.length < +key || +key < 0) {
      continue;
    }
    let sensorItem = _.find(kit.properties, {number: +key});

    if (!sensorItem) {
      continue;
    }
    sensorItem.deviceId = value.cluster;
    sensorItem.clusterName = value.clusterName;
    sensorItem.coordinate = value.coordinate;
    if (clusterIndex === -1) {
      clusteredArray.push([sensorItem]);
    } else {
      clusteredArray[clusterIndex].push(sensorItem);
    }
  }
  return clusteredArray.map(cluster => _.sortBy(cluster, 'number')).splice(1);
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getNotificationIcon(notificationName) {
  switch (notificationName) {
    case 'CASHFLOW':
    case "CASHFLOW_YIELD_UPDATES":
    case "CASHFLOW_INCOME_UPDATES":
    case "CASHFLOW_EXPENSE_UPDATES":
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification icon-red"}
                       icon="dollar-sign"/></div>);
    case 'DAILY UPDATE':
    case "FARM_SCOUTING_REPORT_UPDATES":
    case "FARM_TASK_REPORT_UPDATES":
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification"}
                       icon="check-square"/></div>);
    case 'LIMIT EXCEEDS':
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <img className={"icon-in-notification"}
               height={'30px'} width={'24px'}
               src={limitexceed} alt="limit exceed"/>
        </div>);
    case 'DEVICE OFFLINE':
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification bg-light-green"}
                       icon={"anchor"}/></div>);
    case 'SENSOR ERROR':
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification icon-red"}
                       icon={"radio"}/></div>);
    default:
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification"}
                       icon={"radio"}/></div>);
  }
}

export function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  let data = new Date(result).toISOString().slice(0, 10)
  return data;
}

export function s3credential() {
  return {
    dirName: "farm-images",
    bucketName: 'senzagro-app',
    region: 'us-east-1',
    accessKeyId: 'AKIA5XGNU22IOH7IWSV4',
    secretAccessKey: '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',
    s3Url: 'https://senzagro-app.s3.amazonaws.com',
  }
}

export function InformativeMSG(msg) {
  return (<div className={"col-11 informative-msg"}>
    <FeatherIcon className={"informative-msg-icon"} icon="alert-circle"/>
    <span className={"agronomy-practices-text"}>{msg}</span>
  </div>)
}

export function getDistricts() {
  return ["Colombo", "Gampaha", "Kalutara", "Kandy", "Matale", "Nuwara Eliya", "Galle", "Matara",
    "Hambantota", "Jaffna", "Kilinochchi", "Mannar", "Vavuniya", "Mullaitivu", "Batticaloa", "Ampara", "Trincomalee",
    "Kurunegala", "Puttalam", "Anuradhapura", "Polonnaruwa", "Badulla", "Moneragala", "Ratnapura", "Kegalle"];
}

export function isKeells(selectedCorporate) {
  if (process.env.REACT_APP_DOMAIN === "KEELLS") {
    return true;
  }

  return ["61ea3d382d3b876a49dfe5eb", "626780382d3b873354db7431", "6267a04f2d3b873354db9a57", "6267a0a72d3b873354db9ac8",
    "62dff7086f3c941599959568", "62dffded6f3c94159995956e", "62dfff976f3c94159995956f", "62e001de6f3c941599959570", "62e002966f3c941599959571",
    "62e0035f6f3c941599959572", "62e0041b6f3c941599959573", "649eb3b36f3c9441717bc572", "64c4841e6f3c944171d1f058", "64c484cb6f3c944171d1f05a",
    "6267a0f12d3b873354db9b18", "6267a10d2d3b873354db9b1f", "6267a1292d3b873354db9b49"].includes(selectedCorporate);
}

export function getWhiteLabel(selectedCorporate) {
  if (isKeells(selectedCorporate)) {
    return WHITE_LABEL.KEELLS;
  }

  return;
}

export function goToHomePage(history, isFromHome) {
  const role = getRole();
  const refs = getRefCount();

  if (role === 300) {
    history.push('/system-config/processing-plant');
  } else if ([510, 410].includes(role)) {
    history.push('/xindicate/participant');
  } else if ([55, 54, 53, 52, 51, 210].includes(role)) {
    history.push('/xindicate/dashboard/participant');
  } else if (role === 500) {
    history.push('/xindicate/dashboard/participant');
  } else if (refs === 1 && (role >= 1000 && role <= 9999)) {
    history.push("/" + getReferences() + '/dashboard');
  } else if (role === 100000) {
    if (refs === 1) {
      history.push('/buyer/' + getReferences() + '/buyer-details');
    } else {
      history.push('/buyer');
    }
  } else if (role === 9) {
    history.push('/ccr/ccr-supply');
  } else if (!isFromHome) {
    history.push('/home');
  }
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function incrementString(string) {
  let number = string.match(/\d+/) === null ? 0 : string.match(/\d+/)[0];
  let numberLength = number.length
  number = (parseInt(number) + 1).toString();
  while (number.length < numberLength) {
    number = "0" + number;
  }
  return string.replace(/[0-9]/g, '').concat(number);
}

export function getTodayDate(dayDiff) {
  let today = new Date();

  if (dayDiff) {
    today.setDate(today.getDate() + dayDiff);
  }
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
}


export function getSensorLimits(sensor) {
  if (sensor === "T") {
    return {min: -100, max: 100, step: 1, defaultLow: 50, defaultHigh: 80};
  } else if (sensor === "CN") {
    return {min: 0, max: 5000, step: 10, defaultLow: 2, defaultHigh: 3};
  } else if (sensor === "LIA1") {
    return {min: 1, max: 65535, step: 1, defaultLow: 2000, defaultHigh: 3000};
  } else if (sensor === "PH") {
    return {min: 1, max: 14, step: 0.05, defaultLow: 5, defaultHigh: 8};
  } else if (sensor === "A") {
    return {min: -1, max: 2, step: 1, defaultLow: 0, defaultHigh: 1};
  } else if (sensor === "RL") {
    return {min: -2, max: 2, step: 1, defaultLow: -1, defaultHigh: 1};
  }

  // Default values if no specific case matches
  return {min: 0, max: 100, step: 1, defaultLow: 20, defaultHigh: 30};
}

export function sortAndSeparateTasks(tasks) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of the day

  // Sort tasks by endDate
  const sortedTasks = [...tasks].sort((a, b) => {
    const dateA = new Date(a.endDate);
    const dateB = new Date(b.endDate);
    return dateB - dateA;
  });

  // Separate tasks into two groups
  const tasksAfterToday = sortedTasks.filter(task => new Date(task.endDate) >= today);
  const tasksBeforeToday = sortedTasks.filter(task => new Date(task.endDate) < today);

  return { tasksAfterToday, tasksBeforeToday };
};

export function getTodayDateTime(){
  const currentDateTime = new Date()
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Asia/Colombo'
  };
  const formatter = new Intl.DateTimeFormat('sv-SE', options);
  const parts = formatter.formatToParts(currentDateTime);

  const year = parts.find(part => part.type === 'year').value;
  const month = parts.find(part => part.type === 'month').value;
  const day = parts.find(part => part.type === 'day').value;
  const hour = parts.find(part => part.type === 'hour').value;
  const minute = parts.find(part => part.type === 'minute').value;

  return `${year}-${month}-${day}T${hour}:${minute}`;
}


